export const PaymentMethods = [

    {
        id:0,
        Name  : "Credit / Debit / ATM Cards",
        logo : 'credit-card.svg',
        Discription : "Use Visa, Mastercard, Rupay, American Express Etc."
    },
    {
        id:1,
        Name  : "Net Banking",
        logo : 'net-banking.svg',
        Discription : "All Major Banks Available."
    },
    {
        id:2,
        Name  : "UPI",
        logo : 'upi.svg',
        Discription : "Pay Directly from Upi Linked Bank Account."
    },
    {
        id:3,
        Name  : "EMI",
        logo : 'emi.svg',
        Discription : "All major Banks Available for EMI."
    },
    {
        id:4,
        Name  : "Wallets",
        logo : 'wallets.svg',
        Discription : "Choose Mobikwik , Phonepe , Payzapp , Amazon etc."
    },
    {
        id:5,
        Name  : "Google Pay",
        logo : 'googlepay.svg',
        Discription : "Pay with Google Pay."
    },
    {
        id:6,
        Name  : "Gift Card",
        logo : 'giftcard.svg',
        Discription : "Pay with Gift Card."
    },
    {
        id:7,
        Name  : "Pay Later",
        logo : 'paylater.svg',
        Discription : "Simpl , ICICI Bank Pay Later and Mobikwik Zip available."
    }
]