import React, { useState } from "react";

function DataBar({ Theme, From, Stops, Refundability, FFName, To }) {

  return (
    <div className="frame-24">
      <div className="frame-250">
        <div className={`frame-243 ${Theme}_semibold color_white font16`}>
          <div className="place">{From}</div>
          <div className="frame-170">
            <div className="line-between"></div>
            <div className="frame-202">
              <div className={`address ${Theme}_regular color_white font14`}>
                {Stops}
              </div>
            </div>
            <div className="line-between"></div>
          </div>
          <div className="place">{To}</div>
        </div>
      </div>
      <div className="frame-268">
        <div className="frame-243-1">
          <div
            className={`partially-refundable ${Theme}_semibold color_${
              Refundability === "Refundable" ? "robins-egg-blue" : "cherry"
            } font16`}
          >
            {Refundability}
          </div>
        </div>
        <div className="frame-3">
          <div className={`flexi-economy ${Theme}_semibold color_white font16`}>
            {FFName.Name.heading === undefined
              ? FFName.Name
              : FFName.Name.heading}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DataBar;
