import React from 'react'

function HotelPayments() {
  return (
    <div>
      HotelPayments
    </div>
  )
}

export default HotelPayments
