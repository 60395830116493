import axios from "axios";
import { MAIN_URL } from "../../Config/Api";

export async function getPaymentData(id) {

  try {

    const response = await axios.get(
      `${MAIN_URL}payments/${id}`
    );

    return response;
  } catch (error) {
    console.log("the api throw error is  : ", error);
    throw error;
  }

}
