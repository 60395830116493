import { createSlice } from "@reduxjs/toolkit";
import { getPaymentData } from "../../Apis/Payment-api";

const initialState = {
  isOffline: false,
  Loading: true,
  paymentType: "",

  PaymentData : null,
  BookingData : null,
  Theme  : "",

  AncellariesPrice : {Seats : 0, Meals :  0 , Baggage : 0 },
  isPriceChanged : false ,
  PriceChangedData : {Message  : ""} ,

  hasError: false,
  ErrorType: { Heading: "", Message: "" },
};

export const fetch_Payment = (location) => {
  return async (dispatch, getState) => {
    const statesData = getState();

    try {

      // Get the URL path
      const urlPath = location.pathname;

      // Extract the 'id' and 'Theme' values from the URL using string manipulation
      const [idWithTheme] = urlPath.split("id=").slice(1); // Gets everything after 'id='
      const [id, Theme] = idWithTheme.split("+"); // Split id and theme

      if (!id) {
        throw new Error(`No id`);
      }

      if (!Theme || (Theme !== "proxima" && Theme !== "averta")) {
        throw new Error(`Invalid theme`);
      }

      let data = await getPaymentData(id);

      console.log("The payment data is : ", data);

      dispatch(setLoading(false));

      data.data.booking.flight_data = JSON.parse(data.data.booking.flight_data)
      data.data.booking.traveller_data = JSON.parse(data.data.booking.traveller_data)

      dispatch(setPaymentData(data.data.payment));
      dispatch(setBookingData(data.data.booking));

      dispatch(setTheme(Theme))

      let paymentType = data.data.payment.pay_type;

      if(paymentType == "official-flight"){
        dispatch(setpaymentType("Flight"))

        let ancellaryPrices = {
          Seats : 0,
          Meals : 0,
          Baggage : 0,
        }

        // update the ancellaries prices if there 
        if(data.data.booking.traveller_data.ancellariesCodes.Seats.length !== 0){
          data.data.booking.traveller_data.ancellariesCodes.Seats.forEach(element => {
            ancellaryPrices.Seats += element.Price
          });
        }
        if(data.data.booking.traveller_data.ancellariesCodes.Meals.length !== 0){
          data.data.booking.traveller_data.ancellariesCodes.Meals.forEach(element => {
            ancellaryPrices.Meals += element.Price
          });
        }
        if(data.data.booking.traveller_data.ancellariesCodes.Bags.length !== 0){
          data.data.booking.traveller_data.ancellariesCodes.Bags.forEach(element => {
            ancellaryPrices.Baggage += element.Price
          });
        }
        
        dispatch(setAncellariesPrice({Seats : ancellaryPrices.Seats, Meals :  ancellaryPrices.Meals , Baggage : ancellaryPrices.Baggage }));

      }

    } catch (e) {
      console.log(e);
      dispatch(setLoading(false));
      dispatch(sethasError(true));
      dispatch(
        setErrorType({
          Heading: "Invalid Payment Link",
          Message:
            "The Payment Link is InValid please Go Back and start a anew payment ",
        })
      );
    }
  };
};

const HomePage_SLICE = createSlice({
  name: "HomePageSearchBarFlight",
  initialState,
  reducers: {
    sethasError: (state, action) => {
      state.hasError = action.payload;
    },
    setErrorType: (state, action) => {
      state.ErrorType = action.payload;
    },
    setLoading: (state, action) => {
      state.Loading = action.payload;
    },
    setLoading: (state, action) => {
      state.Loading = action.payload;
    },
    setpaymentType: (state, action) => {
      state.paymentType = action.payload;
    },
    setPaymentData: (state, action) => {
      state.PaymentData = action.payload;
    },
    setBookingData: (state, action) => {
      state.BookingData = action.payload;
    },
    setTheme: (state, action) => {
      state.Theme = action.payload;
    },
    setAncellariesPrice: (state, action) => {
      state.AncellariesPrice = action.payload;
    },
  },
});

export const { sethasError, setErrorType, setLoading, setpaymentType , setPaymentData , setBookingData , setTheme , setAncellariesPrice } =
  HomePage_SLICE.actions;

export default HomePage_SLICE.reducer;
