import React, { useEffect, useState, useMemo, useCallback } from "react";
import "./index.css";
import FlightDetailsIndicator from "../Components/FlightDetailsIndicator/FlightDetailsIndicator";
import FligthDetails from "../Components/FligthDetails";

import TagsContainer from "../Components/TagsContainer";
import {
  formatTime,
  getTotalMinutes,
} from "../../../../../Utils/Date_Time_Calendar_Functions";
import MainPaymentMethods from "../../../../Global/PaymentMethods/MainPaymentMethods";

function OnewayData({ Theme, Data , Amount}) {
  let [showDetails, setShowDetails] = useState(false);

  return (
    <div className="main-section-wrapper">
      <div className="main-data-section">
        <div className="flight-detail-header" style={showDetails == true ? {paddingBottom:"15px"} : {}}>
          <div
            className={`${Theme}_semibold color_${
              Theme == "proxima" ? "magic-potion" : "optera-galaxy"
            } font16`}
          >
            Your Bookings
          </div>
          <div
            className={`${Theme}_semibold color_${
              Theme == "proxima" ? "endeavour" : "endeavour"
            } font16 show-hide`}
            onClick={() => setShowDetails(!showDetails)}
          >
            {showDetails ? "Hide" : "Show"} Details
          </div>
        </div>

        {showDetails && (
          <>
            <FlightDetailsIndicator
              Theme={Theme}
              Type={Data.Connections == undefined ? 1 : 2}
              Data={Data}
              isReturn={false}
              isTrip={false}
              TripName={true}
              Refundability={
                Data.Connections == undefined
                  ? Data[0].IsRefundable == true
                    ? "Refundable"
                    : "Non-Refundable"
                  : [
                      Data.Connections[0].IsRefundable == true
                        ? "Refundable"
                        : "Non-Refundable",
                      Data.Connections[1].IsRefundable == true
                        ? "Refundable"
                        : "Non-Refundable",
                    ]
              }
              From={
                Data.Connections == undefined
                  ? Data[0].Segments[0].Origin.Airport.CityName
                  : [
                      Data.Connections[0].Segments[0].Origin.Airport.CityName,
                      Data.Connections[1].Segments[0].Origin.Airport.CityName,
                    ]
              }
              To={
                Data.Connections == undefined
                  ? Data[0].Segments[Data[0].Segments.length - 1].Destination
                      .Airport.CityName
                  : [
                      Data.Connections[0].Segments[
                        Data.Connections[0].Segments.length - 1
                      ].Destination.Airport.CityName,
                      Data.Connections[1].Segments[
                        Data.Connections[1].Segments.length - 1
                      ].Destination.Airport.CityName,
                    ]
              }
              Stops={
                Data.Connections == undefined
                  ? Data[0].Segments.length === 1
                    ? "Non Stop"
                    : Data[0].Segments.length - 1 + " Stops"
                  : [
                      Data.Connections[0].Segments.length === 1
                        ? "Non Stop"
                        : Data.Connections[0].Segments.length - 1 + " Stops",
                      Data.Connections[1].Segments.length === 1
                        ? "Non Stop"
                        : Data.Connections[1].Segments.length - 1 + " Stops",
                    ]
              }
              Time={
                Data.Connections == undefined
                  ? formatTime(
                      getTotalMinutes(
                        Data[0].Segments[0].Origin.DepTime,
                        Data[0].Segments[Data[0].Segments.length - 1]
                          .Destination.ArrTime
                      )
                    )
                  : formatTime(
                      getTotalMinutes(
                        Data.Connections[0].Segments[0].Origin.DepTime,
                        Data.Connections[1].Segments[
                          Data.Connections[1].Segments.length - 1
                        ].Destination.ArrTime
                      )
                    )
              }
              FFName={
                Data.Connections == undefined
                  ? Data[0].ChoosedFamily
                  : [
                      Data.Connections[0].ChoosedFamily,
                      Data.Connections[1].ChoosedFamily,
                    ]
              }
            />

            <div className={`FD-wrapper ${Theme}`}>
              {(Data.Connections !== undefined
                ? Data.Connections[0].Segments.length == 1
                : Data[0].Segments.length == 1) && (
                <FligthDetails
                  Theme={Theme}
                  data={
                    Data.Connections !== undefined
                      ? Data.Connections[0].Segments[0]
                      : Data[0].Segments[0]
                  }
                  Tags={
                    <TagsContainer
                      Theme={Theme}
                      data={
                        Data.Connections !== undefined
                          ? Data.Connections[0].Segments
                          : Data[0].Segments
                      }
                      index={0}
                      single={true}
                    />
                  }
                />
              )}

              {(Data.Connections !== undefined
                ? Data.Connections[0].Segments.length == 2
                : Data[0].Segments.length == 2) && (
                <>
                  <FligthDetails
                    Theme={Theme}
                    data={
                      Data.Connections !== undefined
                        ? Data.Connections[0].Segments[0]
                        : Data[0].Segments[0]
                    }
                    Tags={
                      <TagsContainer
                        Theme={Theme}
                        data={
                          Data.Connections !== undefined
                            ? Data.Connections[0].Segments
                            : Data[0].Segments
                        }
                        index={[0, 1]}
                        single={false}
                      />
                    }
                  />

                  <FligthDetails
                    Theme={Theme}
                    data={
                      Data.Connections !== undefined
                        ? Data.Connections[0].Segments[1]
                        : Data[0].Segments[1]
                    }
                    Tags={
                      <TagsContainer
                        Theme={Theme}
                        data={
                          Data.Connections !== undefined
                            ? Data.Connections[0].Segments
                            : Data[0].Segments
                        }
                        index={1}
                        single={true}
                      />
                    }
                  />
                </>
              )}

              {(Data.Connections !== undefined
                ? Data.Connections[0].Segments.length == 3
                : Data[0].Segments.length == 3) && (
                <>
                  <FligthDetails
                    Theme={Theme}
                    data={
                      Data.Connections !== undefined
                        ? Data.Connections[0].Segments[0]
                        : Data[0].Segments[0]
                    }
                    Tags={
                      <TagsContainer
                        Theme={Theme}
                        data={
                          Data.Connections !== undefined
                            ? Data.Connections[0].Segments
                            : Data[0].Segments
                        }
                        index={[0, 1]}
                        single={false}
                      />
                    }
                  />

                  <FligthDetails
                    Theme={Theme}
                    data={
                      Data.Connections !== undefined
                        ? Data.Connections[0].Segments[1]
                        : Data[0].Segments[1]
                    }
                    Tags={
                      <TagsContainer
                        Theme={Theme}
                        data={
                          Data.Connections !== undefined
                            ? Data.Connections[0].Segments
                            : Data[0].Segments
                        }
                        index={[1, 2]}
                        single={false}
                      />
                    }
                  />

                  <FligthDetails
                    Theme={Theme}
                    data={
                      Data.Connections !== undefined
                        ? Data.Connections[0].Segments[2]
                        : Data[0].Segments[2]
                    }
                    Tags={
                      <TagsContainer
                        Theme={Theme}
                        data={
                          Data.Connections !== undefined
                            ? Data.Connections[0].Segments
                            : Data[0].Segments
                        }
                        index={2}
                        single={true}
                      />
                    }
                  />
                </>
              )}

              {Data.Connections != undefined && (
                <div
                  className={`${Theme}_regular color_eerie-black font13`}
                  style={{ margin: "auto" }}
                >
                  {" "}
                  -- Connection --{" "}
                </div>
              )}

              {Data.Connections != undefined &&
                Data.Connections[1].Segments.length == 1 && (
                  <FligthDetails
                    Theme={Theme}
                    data={Data.Connections[1].Segments[0]}
                    Tags={
                      <TagsContainer
                        Theme={Theme}
                        data={Data.Connections[1].Segments}
                        index={0}
                        single={true}
                      />
                    }
                  />
                )}

              {Data.Connections != undefined &&
                Data.Connections[1].Segments.length == 2 && (
                  <>
                    <FligthDetails
                      Theme={Theme}
                      data={Data.Connections[1].Segments[0]}
                      Tags={
                        <TagsContainer
                          Theme={Theme}
                          data={Data.Connections[1].Segments}
                          index={[0, 1]}
                          single={false}
                        />
                      }
                    />

                    <FligthDetails
                      Theme={Theme}
                      data={Data.Connections[1].Segments[1]}
                      Tags={
                        <TagsContainer
                          Theme={Theme}
                          data={Data.Connections[1].Segments}
                          index={1}
                          single={true}
                        />
                      }
                    />
                  </>
                )}

              {Data.Connections != undefined &&
                Data.Connections[1].Segments.length == 3 && (
                  <>
                    <FligthDetails
                      Theme={Theme}
                      data={Data.Connections[1].Segments[0]}
                      Tags={
                        <TagsContainer
                          Theme={Theme}
                          data={Data.Connections[1].Segments}
                          index={[0, 1]}
                          single={false}
                        />
                      }
                    />

                    <FligthDetails
                      Theme={Theme}
                      data={Data.Connections[1].Segments[1]}
                      Tags={
                        <TagsContainer
                          Theme={Theme}
                          data={Data.Connections[1].Segments}
                          index={[1, 2]}
                          single={false}
                        />
                      }
                    />

                    <FligthDetails
                      Theme={Theme}
                      data={Data.Connections[1].Segments[2]}
                      Tags={
                        <TagsContainer
                          Theme={Theme}
                          data={Data.Connections[1].Segments}
                          index={2}
                          single={true}
                        />
                      }
                    />
                  </>
                )}
            </div>
          </>
        )}
      </div>

           {/* // payment methods and gateway */}
           <MainPaymentMethods Theme={Theme} Amount={Amount}/>
    </div>
  );
}

export default OnewayData;
