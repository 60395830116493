import React from 'react'
import { MAIN_STFARES_URL } from '../../../Config/Api';

function ErrorPage({Heading ,  Message}) {

  const redirectToExternalPage = () => {
    window.location.href = MAIN_STFARES_URL;
  };

  return (
    <div>
      <h2>{Heading ? Heading : "Page Not Found"}</h2>
      <p>{Message ? Message : "The Page You are looking for here is not available "} </p>

      <button onClick={redirectToExternalPage}>
        Go to Home Page
      </button>

    </div>
  )
}

export default ErrorPage
