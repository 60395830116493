import { Suspense, lazy } from "react";
import { createBrowserRouter } from "react-router-dom";
import MainHomePage from "../Pages/HomePage/MainHomePage";
import MainResultPage from "../Pages/ResultPage/MainResultPage";
import ErrorPage from "../Pages/Global/Error/ErrorPage";
import Header from "../Components/Header";

const router = createBrowserRouter([
  {
    path: "/:id",
    exact: true,
    element: (
    <>
      <MainHomePage />
    </>),
  },
  {
    path: "/payment-done/:id",
    exact: true,
    element: <MainResultPage />,
  },
  {
    path: "*",
    exact: true,
    element: <ErrorPage />,
  }
]);

export default router;
