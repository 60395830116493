import React from 'react'

function Header() {
  return (
    <div>
      Header
    </div>
  )
}

export default Header
