import React from 'react'
import "./GooglePay.css";

function GooglePay() {
  return (
    <div>
      
    </div>
  )
}

export default GooglePay
