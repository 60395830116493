import React, { useEffect, useState } from "react";
import Header from "../../../Components/Header";
import "./FlightPayments.css";
import Timer from "../components/Timer/Timer";
import { useDispatch, useSelector } from "react-redux";
import { getTheResultType } from "../../../Utils/FlightFunc";

import OnewayData from "./Components/OnewayData";
import RoundwayDom from "./Components/RoundwayData_Domestic";
import RoundwayINTSplit from "./Components/Round_International/Split";
import RoundwayINTCombine from "./Components/Round_International/Combined";
import MultiwayDom2 from "./Components/MultiDomestic/2Row";
import MultiwayDom3 from "./Components/MultiDomestic/3Row";
import MultiwayDom4 from "./Components/MultiDomestic/4Row";
import MultiwayINTSplit2 from "./Components/MultiInternational/2Row/Split";
import MultiwayINTCombine2 from "./Components/MultiInternational/2Row/Combined";
import MultiwayINTSplit3 from "./Components/MultiInternational/3Row/Split";
import MultiwayINTCombine3 from "./Components/MultiInternational/3Row/Combined";
import MultiwayINTSplit4 from "./Components/MultiInternational/4Row/Split";
import MultiwayINTCombine4 from "./Components/MultiInternational/4Row/Combined";
import FareDetailsSB from "./Components/FareDetailsSB/FareDetailsSB";
import MainPaymentMethods from "../../Global/PaymentMethods/MainPaymentMethods";

function FlightPayments({Theme }) {
  
  let dispatch = useDispatch();

  let  {PaymentData , BookingData , AncellariesPrice , isPriceChanged , PriceChangedData } = useSelector((state) => state.HomePageMain);

  let [resultType, setResultType] = useState(1);

  useEffect(() => {
    let getType = getTheResultType(BookingData.name);

    setResultType(getType);
  }, [BookingData]);

  return (
    <>
      <Header />
      <div className={`flight-payments ${Theme}`}>
        <Timer Theme={Theme} />

        <div className={`main-bar-section ${Theme}`}>
          <div className="detail-section">
          
              <>
                {(resultType === 1 || resultType === 2) && (
                  <OnewayData Theme={Theme} Data={BookingData.flight_data} Amount={PaymentData.amount}/>
                )}
                {resultType === 3 && (
                  <RoundwayDom Theme={Theme} Data={BookingData.flight_data} Amount={PaymentData.amount}/>
                )}
                {resultType === 4 &&
                  (BookingData.flight_data[0] == undefined ? (
                    <RoundwayINTSplit Theme={Theme} Data={BookingData.flight_data} Amount={PaymentData.amount}/>
                  ) : (
                    <RoundwayINTCombine Theme={Theme} Data={BookingData.flight_data} Amount={PaymentData.amount}/>
                  ))}
                {resultType === 5 && (
                  <MultiwayDom2 Theme={Theme} Data={BookingData.flight_data} Amount={PaymentData.amount}/>
                )}
                {resultType === 7 && (
                  <MultiwayDom3 Theme={Theme} Data={BookingData.flight_data} Amount={PaymentData.amount}/>
                )}
                {resultType === 9 && (
                  <MultiwayDom4 Theme={Theme} Data={BookingData.flight_data} Amount={PaymentData.amount}/>
                )}
                {resultType === 6 &&
                  (BookingData.flight_data[0] == undefined ? (
                    <MultiwayINTSplit2 Theme={Theme} Data={BookingData.flight_data} Amount={PaymentData.amount}/>
                  ) : (
                    <MultiwayINTCombine2 Theme={Theme} Data={BookingData.flight_data} Amount={PaymentData.amount}/>
                  ))}
                {resultType === 8 &&
                  (BookingData.flight_data[0] == undefined ? (
                    <MultiwayINTSplit3 Theme={Theme} Data={BookingData.flight_data} Amount={PaymentData.amount}/>
                  ) : (
                    <MultiwayINTCombine3 Theme={Theme} Data={BookingData.flight_data} Amount={PaymentData.amount}/>
                  ))}
                {resultType === 10 &&
                  (BookingData.flight_data[0] == undefined ? (
                    <MultiwayINTSplit4 Theme={Theme} Data={BookingData.flight_data} Amount={PaymentData.amount}/>
                  ) : (
                    <MultiwayINTCombine4 Theme={Theme} Data={BookingData.flight_data} Amount={PaymentData.amount}/>
                  ))}
              </>
         
            <div className="fare-summary-section">
            
              <FareDetailsSB

            Theme={Theme}
            ResultType={resultType}
            Data={BookingData.flight_data}
            CouponData={BookingData.coupons_and_discounts_used === null ? {Code : ""} : BookingData.coupons_and_discounts_used}
            AncellariesPrice={AncellariesPrice}

            isPriceChanged={isPriceChanged}
            Message={PriceChangedData.Message}
        
          />

            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FlightPayments;
