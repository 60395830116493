import React, { useEffect, useState } from "react";
import "./CardPayment.css";
import { TextField } from "@mui/material";
import images from "../../../../Utils/importImages";
import MonthSelector from "./Components/MonthSelector";
import YearSelector from "./Components/YearSelector";
import jsonp from "jsonp";
import axios from "axios";

function CardPayment({ Theme, Amount }) {


  const [jsonData, setJsonData] = useState(null);
  const [selectedPaymentOption, setSelectedPaymentOption] = useState("");
  const [cardNames, setCardNames] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPaymentOptions = async () => {
      const accessCode = "AVXF24KJ72BW97FXWB"; // your access code
      const amount = "6000.00";
      const currency = "INR";

      try {
        const response = await axios.get(
          `https://test.ccavenue.com/transaction/transaction.do?command=getJsonData&access_code=${accessCode}&currency=${currency}&amount=${amount}`,
          {
            responseType: "jsonp",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.data) {
          setJsonData(response.data);
          processData(response.data); // Optional: If you want to call another function
        }
      } catch (err) {
        setError(err.message);
      }
    };

    fetchPaymentOptions();
  }, []);

  const processData = (data) => {
    // Extract payment options and populate data
    console.log("Processed Data:", data);
    // handle processing data like card names, EMI plans, etc.
  };

  

  let [CardNumber, setCardNumber] = useState("");
  let [CardHolderName, setCardHolderName] = useState("");
  let [CardHolderBankname, setCardHolderBankname] = useState("");
  let [ExpiryMonth, setExpiryMonth] = useState("");
  let [ExpiryYear, setExpiryYear] = useState("");
  let [CVV, setCVV] = useState("");

  let [CardNumberError, setCardNumberError] = useState("");
  let [CardHolderNameError, setCardHolderNameError] = useState("");
  let [CardHolderBanknameError, setCardHolderBanknameError] = useState("");
  let [ExpiryMonthError, setExpiryMonthError] = useState("");
  let [ExpiryYearError, setExpiryYearError] = useState("");
  let [CVVError, setCVVError] = useState("");

  let onChangeCardNumber = (info) => {
    setCardNumberError("");
    setCardNumber(info.target.value);
  };

  const validateCardNumber = () => {
    if (CardNumber === "") {
      setCardNumberError("Card Number Can't be Empty");
    } else if (!/^\d{16}$/.test(CardNumber)) {
      setCardNumberError("Card Number should contain 16 digits");
    }
  };

  let onChangeCardHolderName = (info) => {
    setCardHolderNameError("");
    setCardHolderName(info.target.value);
  };

  const validateCardHolderName = () => {
    if (CardHolderName === "") {
      setCardHolderNameError("Card Holder Name Can't be Empty");
    } else if (!/^[A-Za-z ]+$/.test(CardHolderName)) {
      setCardHolderNameError(
        "Name on Card should contain only alphabets and spaces"
      );
    }
  };

  let onChangeCardHolderBankname = (info) => {
    setCardHolderBanknameError("");
    setCardHolderBankname(info.target.value);
  };

  const validateCardHolderBankName = () => {
    if (CardHolderBankname === "") {
      setCardHolderBanknameError("Card's Bank Name Can't be Empty");
    } else if (!/^[A-Za-z ]+$/.test(CardHolderBankname)) {
      setCardHolderBanknameError(
        "Card's Bank should contain only alphabets and spaces"
      );
    }
  };

  let [clickedInsideMonth, setClickedInsideMonth] = useState(false);
  let [callMonthValidator, setcallMonthValidator] = useState(false);

  useEffect(() => {
    if (clickedInsideMonth == true && callMonthValidator == true) {
      validateMonth(ExpiryMonth);
    }
  }, [clickedInsideMonth, callMonthValidator]);

  let onChangeExpiryMonth = (info) => {
    setExpiryMonth(info);
    setExpiryMonthError("");
  };

  const validateMonth = () => {
    if (ExpiryMonth === "") {
      setExpiryMonthError("Expiry Month Can't be Empty");
    }
  };

  let [clickedInsideYear, setClickedInsideYear] = useState(false);
  let [callYearValidator, setcallYearValidator] = useState(false);

  useEffect(() => {
    if (clickedInsideYear == true && callYearValidator == true) {
      validateYear(ExpiryYear);
    }
  }, [clickedInsideYear, callYearValidator]);

  let onChangeExpiryYear = (info) => {
    setExpiryYear(info);
    setExpiryYearError("");
  };

  const validateYear = () => {
    if (ExpiryYear === "") {
      setExpiryYearError("Expiry Year Can't be Empty");
    }
  };

  let onChangeCVVNumber = (info) => {
    setCVVError("");
    setCVV(info.target.value);
  };

  const validateCVVNumber = () => {
    if (CVV === "") {
      setCVVError("Card CVV Can't be Empty");
    } else if (!/^\d{3}$/.test(CVV)) {
      setCVVError("CVV Number should be of 3 digits");
    }
  };

  return (
    <div className="cardPayment">
      <TextField
        autoComplete="off"
        onBlur={validateCardNumber} // Validate on blur
        error={!!CardNumberError}
        helperText={CardNumberError}
        value={CardNumber}
        onChange={onChangeCardNumber}
        id="outlined-basic"
        label="Card Number"
        variant="outlined"
        sx={{
          width: "100%",
          "& .MuiInputLabel-root": {
            fontSize: "16px",
            fontFamily: `var(--font-family-${
              Theme == "proxima" ? `${Theme}_nova-regular` : `${Theme}-regular`
            })`,
            fontStyle: "normal",
            fontWeight: "400",
            "&.Mui-focused": {
              color: Theme == "averta" ? "#C2A07C" : "#0057B7", // Color when focused
            },
          },
          "& .MuiOutlinedInput-root": {
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: Theme == "averta" ? "#C2A07C" : "#0057B7",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: Theme == "averta" ? "#C2A07C" : "#0057B7",
            },
          },
        }}
      />

      <div className="cards-logo">
        {[
          "visa.svg",
          "mastercard.svg",
          "maestro.svg",
          "american-express.svg",
          "rupay.svg",
          "diners-club.svg",
        ].map((item) => {
          return <img src={images[item]} alt="" />;
        })}
      </div>

      <TextField
        autoComplete="off"
        onBlur={validateCardHolderName} // Validate on blur
        error={!!CardHolderNameError}
        helperText={CardHolderNameError}
        value={CardHolderName}
        onChange={onChangeCardHolderName}
        id="outlined-basic"
        label="Card Holder Name"
        variant="outlined"
        sx={{
          width: "100%",
          "& .MuiInputLabel-root": {
            fontSize: "16px",
            fontFamily: `var(--font-family-${
              Theme == "proxima" ? `${Theme}_nova-regular` : `${Theme}-regular`
            })`,
            fontStyle: "normal",
            fontWeight: "400",
            "&.Mui-focused": {
              color: Theme == "averta" ? "#C2A07C" : "#0057B7", // Color when focused
            },
          },
          "& .MuiOutlinedInput-root": {
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: Theme == "averta" ? "#C2A07C" : "#0057B7",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: Theme == "averta" ? "#C2A07C" : "#0057B7",
            },
          },
        }}
      />

      <TextField
        autoComplete="off"
        onBlur={validateCardHolderBankName} // Validate on blur
        error={!!CardHolderBanknameError}
        helperText={CardHolderBanknameError}
        value={CardHolderBankname}
        onChange={onChangeCardHolderBankname}
        id="outlined-basic"
        label="Card's Bank Name"
        variant="outlined"
        sx={{
          width: "100%",
          "& .MuiInputLabel-root": {
            fontSize: "16px",
            fontFamily: `var(--font-family-${
              Theme == "proxima" ? `${Theme}_nova-regular` : `${Theme}-regular`
            })`,
            fontStyle: "normal",
            fontWeight: "400",
            "&.Mui-focused": {
              color: Theme == "averta" ? "#C2A07C" : "#0057B7", // Color when focused
            },
          },
          "& .MuiOutlinedInput-root": {
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: Theme == "averta" ? "#C2A07C" : "#0057B7",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: Theme == "averta" ? "#C2A07C" : "#0057B7",
            },
          },
        }}
      />

      <div className="bottom-inputs">
        <MonthSelector
          clickedInside={clickedInsideMonth}
          setClickedInside={setClickedInsideMonth}
          SetCallValidator={setcallMonthValidator}
          Theme={Theme}
          Heading={"Expiry Month"}
          onBlur={validateMonth} // Validate on blur
          error={!!ExpiryMonthError}
          helperText={ExpiryMonthError}
          value={ExpiryMonth}
          onChange={onChangeExpiryMonth}
        />

        <YearSelector
          clickedInside={clickedInsideYear}
          setClickedInside={setClickedInsideYear}
          SetCallValidator={setcallYearValidator}
          Theme={Theme}
          Heading={"Expiry Year"}
          onBlur={validateYear} // Validate on blur
          error={!!ExpiryYearError}
          helperText={ExpiryYearError}
          value={ExpiryYear}
          onChange={onChangeExpiryYear}
        />

        <TextField
          autoComplete="off"
          onBlur={validateCVVNumber} // Validate on blur
          error={!!CVVError}
          helperText={CVVError}
          value={CVV}
          onChange={onChangeCVVNumber}
          id="outlined-basic"
          label="CVV Number"
          variant="outlined"
          sx={{
            width: "100%",
            "& .MuiInputLabel-root": {
              fontSize: "16px",
              fontFamily: `var(--font-family-${
                Theme == "proxima"
                  ? `${Theme}_nova-regular`
                  : `${Theme}-regular`
              })`,
              fontStyle: "normal",
              fontWeight: "400",
              "&.Mui-focused": {
                color: Theme == "averta" ? "#C2A07C" : "#0057B7", // Color when focused
              },
            },
            "& .MuiOutlinedInput-root": {
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: Theme == "averta" ? "#C2A07C" : "#0057B7",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: Theme == "averta" ? "#C2A07C" : "#0057B7",
              },
            },
          }}
        />
      </div>

      <div className="amount-row">
        <div className="amount">
          <img src={images["rupee-sign-big.svg"]} alt="" />
          <div className={`value ${Theme}_bold color_deep-ocean font24`}>
            {Intl.NumberFormat("en-US", { maximumFractionDigits: 2 }).format(
              Amount
            )}
          </div>
          <div className={`text ${Theme}_regular font14 color_deep-ocean`}>
            Due Amount
          </div>
        </div>
        <div className={`button color_white ${Theme}_semibold font16`}>
          Pay Now
        </div>
      </div>

      <div className={`policy-row`}>
        <div className={`text ${Theme}_regular color_gravel font14`}>
          By continuing to pay , I understand and i agree with the{" "}
        </div>
        <div className={`text ${Theme}_regular color_endeavour font14`}>
          privacy policy
        </div>
        <div className={`text ${Theme}_regular color_gravel font14`}> and </div>
        <div className={`text ${Theme}_regular color_endeavour font14`}>
          terms of service
        </div>
        <div className={`text ${Theme}_regular color_gravel font14`}>
          {" "}
          of STFares{" "}
        </div>
      </div>
    </div>
  );
}

export default CardPayment;
