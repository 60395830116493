import React, { useEffect } from "react";
import "./FareDetailsSB.css";
import images from "../../../../../Utils/importImages";
import FareSection from "./FareSection";

export default function FareDetailsSB({
  Theme,
  ResultType,
  Data,
  CouponData,
  isPriceChanged,
  AncellariesPrice,
  Message,
}) {

  const getTotalBaseFare = (FFData) => {
    const { Travellers } = FFData;
    const adultFares = Travellers.Adults
      ? Travellers.Adults.map((adult) => +adult.Fare)
      : [0];
    const childFares = Travellers.Childs
      ? Travellers.Childs.map((child) => +child.Fare)
      : [0];
    const infantFares = Travellers.Infrants
      ? Travellers.Infrants.map((infant) => +infant.Fare)
      : [0];
    return [...adultFares, ...childFares, ...infantFares].reduce(
      (acc, fare) => acc + fare,
      0
    );
  };

  const getTotalTaxFare = (FFData) => {
    const { Travellers } = FFData;
    const adultFares = Travellers.Adults
      ? Travellers.Adults.map((adult) => +adult.Tax)
      : [0];
    const childFares = Travellers.Childs
      ? Travellers.Childs.map((child) => +child.Tax)
      : [0];
    const infantFares = Travellers.Infrants
      ? Travellers.Infrants.map((infant) => +infant.Tax)
      : [0];
    return [...adultFares, ...childFares, ...infantFares].reduce(
      (acc, fare) => acc + fare,
      0
    );
  };

  const baseFare = (ResultType  , Data) => {
    if (ResultType === 1 || ResultType === 2 || ResultType == 3 || ResultType == 4 || ResultType == 5 || ResultType == 6) {
      return Data[0] !== undefined
        ? getTotalBaseFare(Data[0].ChoosedFamily)
        : getTotalBaseFare(Data.Connections[0].ChoosedFamily) +
            getTotalBaseFare(Data.Connections[1].ChoosedFamily);
    } else if (ResultType == 7 || ResultType == 8) {
      return Data[0] !== undefined
      ? getTotalBaseFare(Data[0].ChoosedFamily)
      : getTotalBaseFare(Data.Connections[0].ChoosedFamily) +
            getTotalBaseFare(Data.Connections[1].ChoosedFamily) +
            getTotalBaseFare(Data.Connections[2].ChoosedFamily);
    }  else if (ResultType == 9 || ResultType == 10) {
      return Data[0] !== undefined
      ? getTotalBaseFare(Data[0].ChoosedFamily)
      :  getTotalBaseFare(Data.Connections[0].ChoosedFamily) +
            getTotalBaseFare(Data.Connections[1].ChoosedFamily) +
            getTotalBaseFare(Data.Connections[2].ChoosedFamily) +
            getTotalBaseFare(Data.Connections[3].ChoosedFamily);
    }
 
    else {
 
      return 0;
    }
  };

  const taxFare = (ResultType , Data) => {
    if (ResultType === 1 || ResultType === 2 || ResultType == 3 || ResultType == 4 || ResultType == 5 || ResultType == 6) {
      return Data[0] !== undefined
        ? getTotalTaxFare(Data[0].ChoosedFamily)
        : getTotalTaxFare(Data.Connections[0].ChoosedFamily) +
            getTotalTaxFare(Data.Connections[1].ChoosedFamily);
    } else if (ResultType === 7 || ResultType == 8) {
      return Data[0] !== undefined
      ? getTotalTaxFare(Data[0].ChoosedFamily)
      : getTotalTaxFare(Data.Connections[0].ChoosedFamily) +
            getTotalTaxFare(Data.Connections[1].ChoosedFamily) + getTotalTaxFare(Data.Connections[2].ChoosedFamily);
    } else if (ResultType === 9|| ResultType == 10) {
      return Data[0] !== undefined
      ? getTotalTaxFare(Data[0].ChoosedFamily)
      :  getTotalTaxFare(Data.Connections[0].ChoosedFamily) +
            getTotalTaxFare(Data.Connections[1].ChoosedFamily) + getTotalTaxFare(Data.Connections[2].ChoosedFamily) + getTotalTaxFare(Data.Connections[3].ChoosedFamily);
    }
 
    else {
      // Handle other ResultType cases here
      return 0;
    }
  };

  const getBaseFareBar = (FFData) => {
    let barToReturn = [];
    const { Travellers } = FFData;

    const sumArray = (arr) => arr.reduce((sum, value) => sum + value, 0);

    if (Travellers.Adults != undefined) {
      let totalBaseFare = Travellers.Adults.map((adult) => +adult.Fare);
      barToReturn.push({
        name: "Adult",
        multiplier: Travellers.Adults.length,
        multiplierValue: Travellers.Adults[0].Fare,
        endValue: sumArray(totalBaseFare),
      });
    }
    if (Travellers.Childs != undefined) {
      let totalBaseFare = Travellers.Childs.map((child) => +child.Fare);
      barToReturn.push({
        name: "Child",
        multiplier: Travellers.Childs.length,
        multiplierValue: Travellers.Childs[0].Fare,
        endValue: sumArray(totalBaseFare),
      });
    }
    if (Travellers.Infrants != undefined) {
      let totalBaseFare = Travellers.Infrants.map((infant) => +infant.Fare);
      barToReturn.push({
        name: "Infant",
        multiplier: Travellers.Infrants.length,
        multiplierValue: Travellers.Infrants[0].Fare,
        endValue: sumArray(totalBaseFare),
      });
    }

    return barToReturn;
  };

  const getBaseFareBarConnections = (connectionsData, noOfCon) => {
    const sumArray = (arr) => arr.reduce((sum, value) => sum + value, 0);

    let barToReturn = [];

    if (noOfCon == 2) {
      const Travellers1 = connectionsData[0].ChoosedFamily.Travellers;
      const Travellers2 = connectionsData[1].ChoosedFamily.Travellers;

      if (Travellers1.Adults != undefined) {
        let totalBaseFare1 = Travellers1.Adults.map((adult) => +adult.Fare);
        let totalBaseFare2 = Travellers2.Adults.map((adult) => +adult.Fare);
        barToReturn.push({
          name: "Adult",
          multiplier: Travellers1.Adults.length,
          multiplierValue:
            Travellers1.Adults[0].Fare + Travellers2.Adults[0].Fare,
          endValue: sumArray(totalBaseFare1) + sumArray(totalBaseFare2),
        });
      }
      if (Travellers1.Childs != undefined) {
        let totalBaseFare1 = Travellers1.Childs.map((child) => +child.Fare);
        let totalBaseFare2 = Travellers2.Childs.map((child) => +child.Fare);
        barToReturn.push({
          name: "Child",
          multiplier: Travellers1.Childs.length,
          multiplierValue:
            Travellers1.Childs[0].Fare + Travellers2.Childs[0].Fare,
          endValue: sumArray(totalBaseFare1) + sumArray(totalBaseFare2),
        });
      }
      if (Travellers1.Infrants != undefined) {
        let totalBaseFare1 = Travellers1.Infrants.map((infant) => +infant.Fare);
        let totalBaseFare2 = Travellers2.Infrants.map((infant) => +infant.Fare);
        barToReturn.push({
          name: "Infant",
          multiplier: Travellers1.Infrants.length,
          multiplierValue:
            Travellers1.Infrants[0].Fare + Travellers2.Infrants[0].Fare,
          endValue: sumArray(totalBaseFare1) + sumArray(totalBaseFare2),
        });
      }
    } else if (noOfCon == 3) {
      const Travellers1 = connectionsData[0].ChoosedFamily.Travellers;
      const Travellers2 = connectionsData[1].ChoosedFamily.Travellers;
      const Travellers3 = connectionsData[2].ChoosedFamily.Travellers;

      if (Travellers1.Adults != undefined) {
        let totalBaseFare1 = Travellers1.Adults.map((adult) => +adult.Fare);
        let totalBaseFare2 = Travellers2.Adults.map((adult) => +adult.Fare);
        let totalBaseFare3 = Travellers3.Adults.map((adult) => +adult.Fare);

        barToReturn.push({
          name: "Adult",
          multiplier: Travellers1.Adults.length,
          multiplierValue:
            Travellers1.Adults[0].Fare + Travellers2.Adults[0].Fare + Travellers3.Adults[0].Fare,
          endValue: sumArray(totalBaseFare1) + sumArray(totalBaseFare2) + sumArray(totalBaseFare3),
        });
      }
      if (Travellers1.Childs != undefined) {
        let totalBaseFare1 = Travellers1.Childs.map((child) => +child.Fare);
        let totalBaseFare2 = Travellers2.Childs.map((child) => +child.Fare);
        let totalBaseFare3 = Travellers3.Childs.map((child) => +child.Fare);

        barToReturn.push({
          name: "Child",
          multiplier: Travellers1.Childs.length,
          multiplierValue:
            Travellers1.Childs[0].Fare + Travellers2.Childs[0].Fare + Travellers3.Childs[0].Fare,
          endValue: sumArray(totalBaseFare1) + sumArray(totalBaseFare2) + sumArray(totalBaseFare3),
        });
      }
      if (Travellers1.Infrants != undefined) {
        let totalBaseFare1 = Travellers1.Infrants.map((infant) => +infant.Fare);
        let totalBaseFare2 = Travellers2.Infrants.map((infant) => +infant.Fare);
        let totalBaseFare3 = Travellers3.Infrants.map((infant) => +infant.Fare);
        barToReturn.push({
          name: "Infant",
          multiplier: Travellers1.Infrants.length,
          multiplierValue:
            Travellers1.Infrants[0].Fare + Travellers2.Infrants[0].Fare + Travellers3.Infrants[0].Fare,
          endValue: sumArray(totalBaseFare1) + sumArray(totalBaseFare2) + sumArray(totalBaseFare3),
        });
      }

    } else if (noOfCon == 4) {
      const Travellers1 = connectionsData[0].ChoosedFamily.Travellers;
      const Travellers2 = connectionsData[1].ChoosedFamily.Travellers;
      const Travellers3 = connectionsData[2].ChoosedFamily.Travellers;
      const Travellers4 = connectionsData[3].ChoosedFamily.Travellers;

      if (Travellers1.Adults != undefined) {
        let totalBaseFare1 = Travellers1.Adults.map((adult) => +adult.Fare);
        let totalBaseFare2 = Travellers2.Adults.map((adult) => +adult.Fare);
        let totalBaseFare3 = Travellers3.Adults.map((adult) => +adult.Fare);
        let totalBaseFare4 = Travellers4.Adults.map((adult) => +adult.Fare);

        barToReturn.push({
          name: "Adult",
          multiplier: Travellers1.Adults.length,
          multiplierValue:
            Travellers1.Adults[0].Fare + Travellers2.Adults[0].Fare + Travellers3.Adults[0].Fare  + Travellers4.Adults[0].Fare,
          endValue: sumArray(totalBaseFare1) + sumArray(totalBaseFare2) + sumArray(totalBaseFare3) + sumArray(totalBaseFare4),
        });
      }
      if (Travellers1.Childs != undefined) {
        let totalBaseFare1 = Travellers1.Childs.map((child) => +child.Fare);
        let totalBaseFare2 = Travellers2.Childs.map((child) => +child.Fare);
        let totalBaseFare3 = Travellers3.Childs.map((child) => +child.Fare);
        let totalBaseFare4 = Travellers4.Childs.map((child) => +child.Fare);

        barToReturn.push({
          name: "Child",
          multiplier: Travellers1.Childs.length,
          multiplierValue:
            Travellers1.Childs[0].Fare + Travellers2.Childs[0].Fare + Travellers3.Childs[0].Fare + Travellers4.Childs[0].Fare,
          endValue: sumArray(totalBaseFare1) + sumArray(totalBaseFare2) + sumArray(totalBaseFare3) + sumArray(totalBaseFare4),
        });
      }
      if (Travellers1.Infrants != undefined) {
        let totalBaseFare1 = Travellers1.Infrants.map((infant) => +infant.Fare);
        let totalBaseFare2 = Travellers2.Infrants.map((infant) => +infant.Fare);
        let totalBaseFare3 = Travellers3.Infrants.map((infant) => +infant.Fare);
        let totalBaseFare4 = Travellers4.Infrants.map((infant) => +infant.Fare);
        barToReturn.push({
          name: "Infant",
          multiplier: Travellers1.Infrants.length,
          multiplierValue:
            Travellers1.Infrants[0].Fare + Travellers2.Infrants[0].Fare + Travellers3.Infrants[0].Fare + Travellers4.Infrants[0].Fare,
          endValue: sumArray(totalBaseFare1) + sumArray(totalBaseFare2) + sumArray(totalBaseFare3) + sumArray(totalBaseFare4),
        });
      }
    }

    console.log("barToReturn ias : " , barToReturn);
    
    return barToReturn;
  };

  const coveredBarDataBaseFare = (ResultType , Data) => {
    if (ResultType === 1 || ResultType === 2 || ResultType == 3 || ResultType == 4 || ResultType == 5 || ResultType == 6) {
      return Data[0] !== undefined
        ? getBaseFareBar(Data[0].ChoosedFamily)
        : getBaseFareBarConnections(Data.Connections, 2);
    } else if(ResultType === 7 || ResultType === 8) {
      return Data[0] !== undefined
        ? getBaseFareBar(Data[0].ChoosedFamily)
        :  getBaseFareBarConnections(Data.Connections, 3);
    } else if(ResultType === 9 || ResultType === 10) {
      return Data[0] !== undefined
        ? getBaseFareBar(Data[0].ChoosedFamily)
        :  getBaseFareBarConnections(Data.Connections, 4);
    }
  };

  const getTaxFareBar = (FFData) => {
    let barToReturn = [];
    const { Travellers } = FFData;

    const sumArray = (arr) => arr.reduce((sum, value) => sum + value, 0);

    if (Travellers.Adults != undefined) {
      let totalBaseFare = Travellers.Adults.map((adult) => +adult.Tax);
      barToReturn.push({
        name: "Adult",
        multiplier: Travellers.Adults.length,
        multiplierValue: Travellers.Adults[0].Tax,
        endValue: sumArray(totalBaseFare),
      });
    }
    if (Travellers.Childs != undefined) {
      let totalBaseFare = Travellers.Childs.map((child) => +child.Tax);
      barToReturn.push({
        name: "Child",
        multiplier: Travellers.Childs.length,
        multiplierValue: Travellers.Childs[0].Tax,
        endValue: sumArray(totalBaseFare),
      });
    }
    if (Travellers.Infrants != undefined) {
      let totalBaseFare = Travellers.Infrants.map((infant) => +infant.Tax);
      barToReturn.push({
        name: "Infant",
        multiplier: Travellers.Infrants.length,
        multiplierValue: Travellers.Infrants[0].Tax,
        endValue: sumArray(totalBaseFare),
      });
    }

    return barToReturn;
  };

  const getTaxFareBarConnections = (connectionsData, noOfCon) => {
    const sumArray = (arr) => arr.reduce((sum, value) => sum + value, 0);

    let barToReturn = [];

    if (noOfCon == 2) {
      const Travellers1 = connectionsData[0].ChoosedFamily.Travellers;
      const Travellers2 = connectionsData[1].ChoosedFamily.Travellers;

      if (Travellers1.Adults != undefined) {
        let totalBaseFare1 = Travellers1.Adults.map((adult) => +adult.Tax);
        let totalBaseFare2 = Travellers2.Adults.map((adult) => +adult.Tax);
        barToReturn.push({
          name: "Adult",
          multiplier: Travellers1.Adults.length,
          multiplierValue:
            Travellers1.Adults[0].Tax + Travellers2.Adults[0].Tax,
          endValue: sumArray(totalBaseFare1) + sumArray(totalBaseFare2),
        });
      }
      if (Travellers1.Childs != undefined) {
        let totalBaseFare1 = Travellers1.Childs.map((child) => +child.Tax);
        let totalBaseFare2 = Travellers2.Childs.map((child) => +child.Tax);
        barToReturn.push({
          name: "Child",
          multiplier: Travellers1.Childs.length,
          multiplierValue:
            Travellers1.Childs[0].Tax + Travellers2.Childs[0].Tax,
          endValue: sumArray(totalBaseFare1) + sumArray(totalBaseFare2),
        });
      }
      if (Travellers1.Infrants != undefined) {
        let totalBaseFare1 = Travellers1.Infrants.map((infant) => +infant.Tax);
        let totalBaseFare2 = Travellers2.Infrants.map((infant) => +infant.Tax);
        barToReturn.push({
          name: "Infant",
          multiplier: Travellers1.Infrants.length,
          multiplierValue:
            Travellers1.Infrants[0].Tax + Travellers2.Infrants[0].Tax,
          endValue: sumArray(totalBaseFare1) + sumArray(totalBaseFare2),
        });
      }
    } else if (noOfCon == 3) {
      const Travellers1 = connectionsData[0].ChoosedFamily.Travellers;
      const Travellers2 = connectionsData[1].ChoosedFamily.Travellers;
      const Travellers3 = connectionsData[2].ChoosedFamily.Travellers;

      if (Travellers1.Adults != undefined) {
        let totalBaseFare1 = Travellers1.Adults.map((adult) => +adult.Tax);
        let totalBaseFare2 = Travellers2.Adults.map((adult) => +adult.Tax);
        let totalBaseFare3 = Travellers3.Adults.map((adult) => +adult.Tax);

        barToReturn.push({
          name: "Adult",
          multiplier: Travellers1.Adults.length,
          multiplierValue:
            Travellers1.Adults[0].Tax + Travellers2.Adults[0].Tax + Travellers3.Adults[0].Tax,
          endValue: sumArray(totalBaseFare1) + sumArray(totalBaseFare2) + sumArray(totalBaseFare3),
        });
      }
      if (Travellers1.Childs != undefined) {
        let totalBaseFare1 = Travellers1.Childs.map((child) => +child.Tax);
        let totalBaseFare2 = Travellers2.Childs.map((child) => +child.Tax);
        let totalBaseFare3 = Travellers3.Childs.map((child) => +child.Tax);

        barToReturn.push({
          name: "Child",
          multiplier: Travellers1.Childs.length,
          multiplierValue:
            Travellers1.Childs[0].Tax + Travellers2.Childs[0].Tax + Travellers3.Childs[0].Tax,
          endValue: sumArray(totalBaseFare1) + sumArray(totalBaseFare2) + sumArray(totalBaseFare3),
        });
      }
      if (Travellers1.Infrants != undefined) {
        let totalBaseFare1 = Travellers1.Infrants.map((infant) => +infant.Tax);
        let totalBaseFare2 = Travellers2.Infrants.map((infant) => +infant.Tax);
        let totalBaseFare3 = Travellers3.Infrants.map((infant) => +infant.Tax);
        barToReturn.push({
          name: "Infant",
          multiplier: Travellers1.Infrants.length,
          multiplierValue:
            Travellers1.Infrants[0].Tax + Travellers2.Infrants[0].Tax + Travellers3.Infrants[0].Tax,
          endValue: sumArray(totalBaseFare1) + sumArray(totalBaseFare2) + sumArray(totalBaseFare3),
        });
      }
    }  else if (noOfCon == 4) {
      const Travellers1 = connectionsData[0].ChoosedFamily.Travellers;
      const Travellers2 = connectionsData[1].ChoosedFamily.Travellers;
      const Travellers3 = connectionsData[2].ChoosedFamily.Travellers;
      const Travellers4 = connectionsData[3].ChoosedFamily.Travellers;

      if (Travellers1.Adults != undefined) {
        let totalBaseFare1 = Travellers1.Adults.map((adult) => +adult.Tax);
        let totalBaseFare2 = Travellers2.Adults.map((adult) => +adult.Tax);
        let totalBaseFare3 = Travellers3.Adults.map((adult) => +adult.Tax);
        let totalBaseFare4 = Travellers4.Adults.map((adult) => +adult.Tax);

        barToReturn.push({
          name: "Adult",
          multiplier: Travellers1.Adults.length,
          multiplierValue:
            Travellers1.Adults[0].Tax + Travellers2.Adults[0].Tax + Travellers3.Adults[0].Tax + Travellers4.Adults[0].Tax,
          endValue: sumArray(totalBaseFare1) + sumArray(totalBaseFare2) + sumArray(totalBaseFare3) + sumArray(totalBaseFare4),
        });
      }
      if (Travellers1.Childs != undefined) {
        let totalBaseFare1 = Travellers1.Childs.map((child) => +child.Tax);
        let totalBaseFare2 = Travellers2.Childs.map((child) => +child.Tax);
        let totalBaseFare3 = Travellers3.Childs.map((child) => +child.Tax);
        let totalBaseFare4 = Travellers4.Childs.map((child) => +child.Tax);

        barToReturn.push({
          name: "Child",
          multiplier: Travellers1.Childs.length,
          multiplierValue:
            Travellers1.Childs[0].Tax + Travellers2.Childs[0].Tax + Travellers3.Childs[0].Tax + Travellers4.Childs[0].Tax,
          endValue: sumArray(totalBaseFare1) + sumArray(totalBaseFare2) + sumArray(totalBaseFare3) + sumArray(totalBaseFare4),
        });
      }
      if (Travellers1.Infrants != undefined) {
        let totalBaseFare1 = Travellers1.Infrants.map((infant) => +infant.Tax);
        let totalBaseFare2 = Travellers2.Infrants.map((infant) => +infant.Tax);
        let totalBaseFare3 = Travellers3.Infrants.map((infant) => +infant.Tax);
        let totalBaseFare4 = Travellers4.Infrants.map((infant) => +infant.Tax);
        barToReturn.push({
          name: "Infant",
          multiplier: Travellers1.Infrants.length,
          multiplierValue:
            Travellers1.Infrants[0].Tax + Travellers2.Infrants[0].Tax + Travellers3.Infrants[0].Tax + Travellers4.Infrants[0].Tax,
          endValue: sumArray(totalBaseFare1) + sumArray(totalBaseFare2) + sumArray(totalBaseFare3) + sumArray(totalBaseFare4),
        });
      }
    }

    return barToReturn;
  };

  const getConvenienceFeesConnections = (connectionsData, noOfCon) => {

    if (noOfCon == 2) {
      return connectionsData[0].Fare.ConvenienceFees.Convenience_fees + connectionsData[1].Fare.ConvenienceFees.Convenience_fees
      
    } else if (noOfCon == 3) {
      
      return connectionsData[0].Fare.ConvenienceFees.Convenience_fees + connectionsData[1].Fare.ConvenienceFees.Convenience_fees + connectionsData[2].Fare.ConvenienceFees.Convenience_fees

    }  else if (noOfCon == 4) {

      return connectionsData[0].Fare.ConvenienceFees.Convenience_fees + connectionsData[1].Fare.ConvenienceFees.Convenience_fees + connectionsData[2].Fare.ConvenienceFees.Convenience_fees  + connectionsData[3].Fare.ConvenienceFees.Convenience_fees
    }

  };

  const coveredBarDataTaxFare = (ResultType , Data) => {
    if (ResultType === 1 || ResultType === 2 || ResultType == 3 || ResultType == 4 || ResultType == 5 || ResultType == 6) {
      return Data[0] !== undefined
        ? getTaxFareBar(Data[0].ChoosedFamily)
        : getTaxFareBarConnections(Data.Connections, 2);
    } else if (ResultType === 7 || ResultType === 8) {
      return Data[0] !== undefined
        ? getTaxFareBar(Data[0].ChoosedFamily)
        : getTaxFareBarConnections(Data.Connections, 3);
    }  else if (ResultType === 9 || ResultType === 10) {
      return Data[0] !== undefined
        ? getTaxFareBar(Data[0].ChoosedFamily)
        : getTaxFareBarConnections(Data.Connections, 4);
    }
  };

  const coveredBarDataConvenienceFee = (ResultType , Data) => {
    if (ResultType === 1 || ResultType === 2 || ResultType == 3 || ResultType == 4 || ResultType == 5 || ResultType == 6) {
      return Data[0] !== undefined
        ? Data[0].Fare.ConvenienceFees.Convenience_fees
        : getConvenienceFeesConnections(Data.Connections, 2);
    } else if (ResultType === 7 || ResultType === 8) {
      return Data[0] !== undefined
        ? Data[0].Fare.ConvenienceFees.Convenience_fees
        : getConvenienceFeesConnections(Data.Connections, 3);
    }  else if (ResultType === 9 || ResultType === 10) {
      return Data[0] !== undefined
        ? Data[0].Fare.ConvenienceFees.Convenience_fees
        : getConvenienceFeesConnections(Data.Connections, 4);
    }
  };

  return (
    <div className="fare-details-sidebar">
      <div className={`frame-241-1 ${Theme}`}>
        <div className="frame-242-6">
          <div
            className={`fare-summary ${Theme}_semibold color_magic-potion font16`}
          >
            FARE SUMMARY
          </div>
        </div>
      </div>
      <div className={`frame-244 ${Theme}`}>
        <FareSection
          Theme={Theme}
          hasBorder={true}
          heading={"Base Fare"}
          headingValue={baseFare(ResultType  , Data)}
          coveredBarData={coveredBarDataBaseFare(ResultType , Data)}
        />
        <FareSection
          Theme={Theme}
          hasBorder={(CouponData?.Code !== "") || (AncellariesPrice.Seats !== 0 || AncellariesPrice.Meals !== 0 || AncellariesPrice.Baggage !== 0)}
          heading={"Tax & Other Charges"}
          headingValue={taxFare(ResultType , Data)}
          coveredBarData={coveredBarDataTaxFare(ResultType , Data)
          }
          convenienceCharges={coveredBarDataConvenienceFee(ResultType , Data)}
        />

        {(AncellariesPrice.Seats !== 0 || AncellariesPrice.Meals !== 0 || AncellariesPrice.Baggage !== 0) && (
          <FareSection
            Theme={Theme}
            hasBorder={CouponData?.Code !== ""}
            heading={"Add-Ons"}
            headingValue={AncellariesPrice.Seats + AncellariesPrice.Meals + AncellariesPrice.Baggage}
            coveredBarData={[{ Name: "Seats", Value: AncellariesPrice.Seats } , { Name: "Meals", Value: AncellariesPrice.Meals } , { Name: "Baggage", Value: AncellariesPrice.Baggage }]}
          />
        )}

        {CouponData?.Code !== "" && (
          <FareSection
            Theme={Theme}
            hasBorder={false}
            heading={"Discounts"}
            headingValue={CouponData?.Val}
            coveredBarData={[{ Name: CouponData?.Code, Value: CouponData?.Val }]}
          />
        )}

      </div>
      <div className="frame-245-2 frame-245-3">
        <div className="frame-2-1">
          <div className={`${Theme}_semibold color_magic-potion font18`}>
            Total Amount
          </div>
          <div className="frame-173-3 frame-173-4">
            <img
              className="frame-3"
              src={images["frame-169-19.svg"]}
              alt="Frame 169"
            />
            <div
              className={`text-86 valign-text-middle text-1 ${Theme}_semibold color_eerie-black font18`}
            >
              {baseFare(ResultType  , Data) +
                taxFare(ResultType , Data) +
                coveredBarDataConvenienceFee(ResultType , Data) +
                (AncellariesPrice.Seats + AncellariesPrice.Meals + AncellariesPrice.Baggage) -
                (CouponData?.Code == "" ? 0 : CouponData?.Val)}
            </div>
          </div>
        </div>

        {isPriceChanged == true && (
          <div
            className={`price-identifier-${
              Message.includes("Increased") ? "Increased" : "Decreased"
            } ${Theme}_semibold color_magic-potion font13`}
          >
            {Message}
          </div>
        )}
      </div>
    </div>
  );
}
