import React from 'react'
import "./PayLater.css";

function PayLater() {
  return (
    <div>
      
    </div>
  )
}

export default PayLater
