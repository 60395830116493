import React from 'react'
import "./NetBanking.css"

function NetBanking({ Theme, Amount }) {
  return (
    <div>
      
    </div>
  )
}

export default NetBanking
