import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import ErrorPage from "../Global/Error/ErrorPage";
import { fetch_Payment } from "../../Redux/Slices/Main_HomePage_Slice/HomePage_SLICE";
import FlightPayments from "./FlightPaymentPage/FlightPayments";
import HotelPayments from "./HotelPaymentPage/HotelPayments";

function MainHomePage() {
  let dispatch = useDispatch();

  let HomePageMain = useSelector((state) => state.HomePageMain);

  const location = useLocation();

  useEffect(() => {
    // when page loads fetch the payment according to payment id
    dispatch(fetch_Payment(location));
  }, [location]);

  console.log("HomePageMain is : " , HomePageMain);
  
  return (
    <div>
      {HomePageMain.Loading && "Loading"}
      {!HomePageMain.Loading && HomePageMain.hasError === true && (
        <ErrorPage
          Heading={HomePageMain.ErrorType.Heading}
          Message={HomePageMain.ErrorType.Message}
        />
      )}
      {!HomePageMain.Loading &&
        HomePageMain.hasError === false &&
        HomePageMain.paymentType === "Flight" && (
          <FlightPayments
            Theme={HomePageMain.Theme}
          />
        )}
      {!HomePageMain.Loading &&
        HomePageMain.hasError === false &&
        HomePageMain.paymentType === "Hotel" && <HotelPayments />}


        
    </div>
  );
}

export default MainHomePage;
