export let getTheResultType = (name) => {
  console.log("itr passsed is : ", name);

  // names will be  :

  switch (name) {
    case "Oneway Domestic":
      return 1;
    case "Oneway International":
      return 2;

    case "Roundway Domestic":
      return 3;

    case "Round International":
      return 4;
    case "Multiway Domestic 2":
      return 5;

    case "Multiway International 2":
      return 6;

    case "Multiway Hybgrid 2":
      return 6;

    case "Multiway Domestic 3":
      return 7;

    case "Multiway International 3":
      return 8;

    case "Multiway Hybgrid 3":
      return 8;

    case "Multiway Domestic 4":
      return 9;

    case "Multiway International 4":
      return 10;

    case "Multiway Hybgrid 4":
      return 10;

    default:
      return 1;
  }
};
