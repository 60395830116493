import React, { useState } from "react";
import "./MainPaymentMethods.css";
import { PaymentMethods } from "../../../Constants/PaymentMethods";
import images from "../../../Utils/importImages";
import CardPayment from "./Methods/CardPayment";
import NetBanking from "./Methods/NetBanking";
import UPI from "./Methods/UPI";
import EMI from "./Methods/EMI";
import Wallets from "./Methods/Wallets";
import GooglePay from "./Methods/GooglePay";
import GiftCard from "./Methods/GiftCard";
import PayLater from "./Methods/PayLater";

function MainPaymentMethods({ Theme , Amount}) {
  let [selectedMethod, setselectedMethod] = useState(0);

  return (
    <div className="payment-Methods">
      <div className={`heading ${Theme}_semibold font16 color_deep-ocean`}>
        PAYMENT METHOD
      </div>
      <div className="payment-content">
        <div className="methods">
          {PaymentMethods.map((item , id) => {
            return (
              <div className={`payment-method-item ${id === selectedMethod && "selected"}`} onClick={() => setselectedMethod(id)}>
                <div className="logo">
                  <img src={images[item.logo]} alt="" />
                </div>
                <div className="content">
                  <div className={`name ${Theme}_semibold color_${selectedMethod === id ? "endeavour" : "deep-ocean"} font16`}>{item.Name}</div>
                  <div className={`about ${Theme}_regular color_gravel font14`}>{item.Discription}</div>
                </div>
              </div>
            );
          })}
        </div>

        <div className="gateway-arena">
          {
            selectedMethod == 0 && <CardPayment Theme={Theme} Amount={Amount}/>
          }
          {
            selectedMethod == 2 && <NetBanking Theme={Theme} Amount={Amount} />
          }
          {
            selectedMethod == 3 && <UPI Theme={Theme} Amount={Amount} />
          }
          {
            selectedMethod == 4 && <EMI Theme={Theme} Amount={Amount} />
          }
          {
            selectedMethod == 5 && <Wallets Theme={Theme} Amount={Amount} />
          }
          {
            selectedMethod == 6 && <GooglePay Theme={Theme} Amount={Amount} />
          }
          {
            selectedMethod == 7 && <GiftCard Theme={Theme} Amount={Amount} />
          }
          {
            selectedMethod == 8 && <PayLater Theme={Theme} Amount={Amount} />
          }
        </div>
      </div>
    </div>
  );
}

export default MainPaymentMethods;
