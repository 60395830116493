import React from 'react'

function MainResultPage() {
  return (
    <div>
      Main result Page
    </div>
  )
}

export default MainResultPage
