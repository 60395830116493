import React, { useEffect, useState, useMemo, useCallback } from "react";
import "./index.css";
import FlightDetailsIndicator from "../../Components/FlightDetailsIndicator/FlightDetailsIndicator";
import FligthDetails from "../../Components/FligthDetails";
import {
  formatTime,
  getTotalMinutes,
} from "../../../../../../Utils/Date_Time_Calendar_Functions";
import TagsContainer from "../../Components/TagsContainer";
import MainPaymentMethods from "../../../../../Global/PaymentMethods/MainPaymentMethods";

function MultiwayDom4({ Theme, Data , startButtonShaking , SearchResultType , Amount}) {
 
  return (
    <>
      <div className="main-section-wrapper">

        <div className="main-data-section">
          <div
            className={`flight-details-1 ${Theme}_semibold color_${
              Theme == "proxima" ? "magic-potion" : "optera-galaxy"
            } font16`}
          >
            FLIGHT DETAILS
          </div>

          <FlightDetailsIndicator
            Theme={Theme}
            Type={1}
            Data={Data}
            isReturn={false}
            isTrip={true}
            TripName={"TRIP 1"}
            Refundability={Data.Connections[0].IsRefundable == true
                      ? "Refundable"
                      : "Non-Refundable"
            }
            From={
                    Data.Connections[0].Segments[0].Origin.Airport
                      .CityName
            }
            To={
                    Data.Connections[0].Segments[
                      Data.Connections[0].Segments.length - 1
                    ].Destination.Airport.CityName
                   
            }
            Stops={
                    Data.Connections[0].Segments.length === 1
                      ? "Non Stop"
                      : Data.Connections[0].Segments.length -
                        1 +
                        " Stops"
            }
            Time={formatTime(
                    getTotalMinutes(
                      Data.Connections[0].Segments[0].Origin
                        .DepTime,
                      Data.Connections[0].Segments[
                        Data.Connections[0].Segments.length - 1
                      ].Destination.ArrTime
                    )
                  )
            }
            FFName={Data.Connections[0].ChoosedFamily
                    
            }
          />

          <div className={`FD-wrapper ${Theme}`}>
            {(Data.Connections[0].Segments.length == 1) && (
              <FligthDetails
                Theme={Theme}
                data={Data.Connections[0].Segments[0]
                }
                Tags={
                  <TagsContainer
                    Theme={Theme}
                    data={Data.Connections[0].Segments}
                    index={0}
                    single={true}
                  />
                }
              />
            )}

            {(Data.Connections[0].Segments.length == 2) && (
              <>
                <FligthDetails
                  Theme={Theme}
                  data={Data.Connections[0].Segments[0]}
                  Tags={
                    <TagsContainer
                      Theme={Theme}
                      data={Data.Connections[0].Segments}
                      index={[0, 1]}
                      single={false}
                    />
                  }
                />

                <FligthDetails
                  Theme={Theme}
                  data={
                    Data.Connections !== undefined
                      ? Data.Connections[0].Segments[1]
                      : Data[0].Segments[1]
                  }
                  Tags={
                    <TagsContainer
                      Theme={Theme}
                      data={Data.Connections[0].Segments}
                      index={1}
                      single={true}
                    />
                  }
                />
              </>
            )}

            {(Data.Connections[0].Segments.length == 3) && (
              <>
                <FligthDetails
                  Theme={Theme}
                  data={Data.Connections[0].Segments[0]}
                  Tags={
                    <TagsContainer
                      Theme={Theme}
                      data={Data.Connections[0].Segments}
                      index={[0, 1]}
                      single={false}
                    />
                  }
                />

                <FligthDetails
                  Theme={Theme}
                  data={Data.Connections[0].Segments[1]}
                  Tags={
                    <TagsContainer
                      Theme={Theme}
                      data={Data.Connections[0].Segments}
                      index={[1, 2]}
                      single={false}
                    />
                  }
                />

                <FligthDetails
                  Theme={Theme}
                  data={Data.Connections[0].Segments[2]}
                  Tags={
                    <TagsContainer
                      Theme={Theme}
                      data={Data.Connections[0].Segments
                         }
                      index={2}
                      single={true}
                    />
                  }
                />
              </>
            )}

          </div>

          <FlightDetailsIndicator
            Theme={Theme}
            Type={1}
            Data={Data}
            isReturn={false}
            isTrip={true}
            TripName={"TRIP 2"}
            Refundability={Data.Connections[1].IsRefundable == true
                      ? "Refundable"
                      : "Non-Refundable"
            }
            From={
                    Data.Connections[1].Segments[0].Origin.Airport
                      .CityName
            }
            To={
                    Data.Connections[1].Segments[
                      Data.Connections[1].Segments.length - 1
                    ].Destination.Airport.CityName
                   
            }
            Stops={
                    Data.Connections[1].Segments.length === 1
                      ? "Non Stop"
                      : Data.Connections[1].Segments.length -
                        1 +
                        " Stops"
            }
            Time={formatTime(
                    getTotalMinutes(
                      Data.Connections[1].Segments[0].Origin
                        .DepTime,
                      Data.Connections[1].Segments[
                        Data.Connections[1].Segments.length - 1
                      ].Destination.ArrTime
                    )
                  )
            }
            FFName={Data.Connections[1].ChoosedFamily
                    
            }
          />

          <div className={`FD-wrapper ${Theme}`}>
            {(Data.Connections[1].Segments.length == 1) && (
              <FligthDetails
                Theme={Theme}
                data={Data.Connections[1].Segments[0]
                }
                Tags={
                  <TagsContainer
                    Theme={Theme}
                    data={Data.Connections[1].Segments}
                    index={0}
                    single={true}
                  />
                }
              />
            )}

            {(Data.Connections[1].Segments.length == 2) && (
              <>
                <FligthDetails
                  Theme={Theme}
                  data={Data.Connections[1].Segments[0]}
                  Tags={
                    <TagsContainer
                      Theme={Theme}
                      data={Data.Connections[1].Segments}
                      index={[0, 1]}
                      single={false}
                    />
                  }
                />

                <FligthDetails
                  Theme={Theme}
                  data={
                    Data.Connections !== undefined
                      ? Data.Connections[1].Segments[1]
                      : Data[0].Segments[1]
                  }
                  Tags={
                    <TagsContainer
                      Theme={Theme}
                      data={Data.Connections[1].Segments}
                      index={1}
                      single={true}
                    />
                  }
                />
              </>
            )}

            {(Data.Connections[1].Segments.length == 3) && (
              <>
                <FligthDetails
                  Theme={Theme}
                  data={Data.Connections[1].Segments[0]}
                  Tags={
                    <TagsContainer
                      Theme={Theme}
                      data={Data.Connections[1].Segments}
                      index={[0, 1]}
                      single={false}
                    />
                  }
                />

                <FligthDetails
                  Theme={Theme}
                  data={Data.Connections[1].Segments[1]}
                  Tags={
                    <TagsContainer
                      Theme={Theme}
                      data={Data.Connections[1].Segments}
                      index={[1, 2]}
                      single={false}
                    />
                  }
                />

                <FligthDetails
                  Theme={Theme}
                  data={Data.Connections[1].Segments[2]}
                  Tags={
                    <TagsContainer
                      Theme={Theme}
                      data={Data.Connections[1].Segments
                         }
                      index={2}
                      single={true}
                    />
                  }
                />
              </>
            )}

          </div>

          <FlightDetailsIndicator
            Theme={Theme}
            Type={1}
            Data={Data}
            isReturn={false}
            isTrip={true}
            TripName={"TRIP 3"}
            Refundability={Data.Connections[2].IsRefundable == true
                      ? "Refundable"
                      : "Non-Refundable"
            }
            From={
                    Data.Connections[2].Segments[0].Origin.Airport
                      .CityName
            }
            To={
                    Data.Connections[2].Segments[
                      Data.Connections[2].Segments.length - 1
                    ].Destination.Airport.CityName
                   
            }
            Stops={
                    Data.Connections[2].Segments.length === 1
                      ? "Non Stop"
                      : Data.Connections[2].Segments.length -
                        1 +
                        " Stops"
            }
            Time={formatTime(
                    getTotalMinutes(
                      Data.Connections[2].Segments[0].Origin
                        .DepTime,
                      Data.Connections[2].Segments[
                        Data.Connections[2].Segments.length - 1
                      ].Destination.ArrTime
                    )
                  )
            }
            FFName={Data.Connections[2].ChoosedFamily
                    
            }
          />

          <div className={`FD-wrapper ${Theme}`}>
            {(Data.Connections[2].Segments.length == 1) && (
              <FligthDetails
                Theme={Theme}
                data={Data.Connections[2].Segments[0]
                }
                Tags={
                  <TagsContainer
                    Theme={Theme}
                    data={Data.Connections[2].Segments}
                    index={0}
                    single={true}
                  />
                }
              />
            )}

            {(Data.Connections[2].Segments.length == 2) && (
              <>
                <FligthDetails
                  Theme={Theme}
                  data={Data.Connections[2].Segments[0]}
                  Tags={
                    <TagsContainer
                      Theme={Theme}
                      data={Data.Connections[2].Segments}
                      index={[0, 1]}
                      single={false}
                    />
                  }
                />

                <FligthDetails
                  Theme={Theme}
                  data={
                    Data.Connections !== undefined
                      ? Data.Connections[2].Segments[1]
                      : Data[0].Segments[1]
                  }
                  Tags={
                    <TagsContainer
                      Theme={Theme}
                      data={Data.Connections[2].Segments}
                      index={1}
                      single={true}
                    />
                  }
                />
              </>
            )}

            {(Data.Connections[2].Segments.length == 3) && (
              <>
                <FligthDetails
                  Theme={Theme}
                  data={Data.Connections[2].Segments[0]}
                  Tags={
                    <TagsContainer
                      Theme={Theme}
                      data={Data.Connections[2].Segments}
                      index={[0, 1]}
                      single={false}
                    />
                  }
                />

                <FligthDetails
                  Theme={Theme}
                  data={Data.Connections[2].Segments[1]}
                  Tags={
                    <TagsContainer
                      Theme={Theme}
                      data={Data.Connections[2].Segments}
                      index={[1, 2]}
                      single={false}
                    />
                  }
                />

                <FligthDetails
                  Theme={Theme}
                  data={Data.Connections[2].Segments[2]}
                  Tags={
                    <TagsContainer
                      Theme={Theme}
                      data={Data.Connections[2].Segments
                         }
                      index={2}
                      single={true}
                    />
                  }
                />
              </>
            )}

          </div>

          <FlightDetailsIndicator
            Theme={Theme}
            Type={1}
            Data={Data}
            isReturn={false}
            isTrip={true}
            TripName={"TRIP 4"}
            Refundability={Data.Connections[3].IsRefundable == true
                      ? "Refundable"
                      : "Non-Refundable"
            }
            From={
                    Data.Connections[3].Segments[0].Origin.Airport
                      .CityName
            }
            To={
                    Data.Connections[3].Segments[
                      Data.Connections[3].Segments.length - 1
                    ].Destination.Airport.CityName
                   
            }
            Stops={
                    Data.Connections[3].Segments.length === 1
                      ? "Non Stop"
                      : Data.Connections[3].Segments.length -
                        1 +
                        " Stops"
            }
            Time={formatTime(
                    getTotalMinutes(
                      Data.Connections[3].Segments[0].Origin
                        .DepTime,
                      Data.Connections[3].Segments[
                        Data.Connections[3].Segments.length - 1
                      ].Destination.ArrTime
                    )
                  )
            }
            FFName={Data.Connections[3].ChoosedFamily
                    
            }
          />

          <div className={`FD-wrapper ${Theme}`}>
            {(Data.Connections[3].Segments.length == 1) && (
              <FligthDetails
                Theme={Theme}
                data={Data.Connections[3].Segments[0]
                }
                Tags={
                  <TagsContainer
                    Theme={Theme}
                    data={Data.Connections[3].Segments}
                    index={0}
                    single={true}
                  />
                }
              />
            )}

            {(Data.Connections[3].Segments.length == 2) && (
              <>
                <FligthDetails
                  Theme={Theme}
                  data={Data.Connections[3].Segments[0]}
                  Tags={
                    <TagsContainer
                      Theme={Theme}
                      data={Data.Connections[3].Segments}
                      index={[0, 1]}
                      single={false}
                    />
                  }
                />

                <FligthDetails
                  Theme={Theme}
                  data={
                    Data.Connections !== undefined
                      ? Data.Connections[3].Segments[1]
                      : Data[0].Segments[1]
                  }
                  Tags={
                    <TagsContainer
                      Theme={Theme}
                      data={Data.Connections[3].Segments}
                      index={1}
                      single={true}
                    />
                  }
                />
              </>
            )}

            {(Data.Connections[3].Segments.length == 3) && (
              <>
                <FligthDetails
                  Theme={Theme}
                  data={Data.Connections[3].Segments[0]}
                  Tags={
                    <TagsContainer
                      Theme={Theme}
                      data={Data.Connections[3].Segments}
                      index={[0, 1]}
                      single={false}
                    />
                  }
                />

                <FligthDetails
                  Theme={Theme}
                  data={Data.Connections[3].Segments[1]}
                  Tags={
                    <TagsContainer
                      Theme={Theme}
                      data={Data.Connections[3].Segments}
                      index={[1, 2]}
                      single={false}
                    />
                  }
                />

                <FligthDetails
                  Theme={Theme}
                  data={Data.Connections[3].Segments[2]}
                  Tags={
                    <TagsContainer
                      Theme={Theme}
                      data={Data.Connections[3].Segments
                         }
                      index={2}
                      single={true}
                    />
                  }
                />
              </>
            )}

          </div>
        </div>

       {/* // payment methods and gateway */}
       <MainPaymentMethods Theme={Theme} Amount={Amount}/>
      </div>

    </>
  );
}

export default MultiwayDom4;
