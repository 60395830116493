import { configureStore } from '@reduxjs/toolkit';
import HomePage_SLICE from "../Slices/Main_HomePage_Slice/HomePage_SLICE"
import FlightPayments_SLICE from '../Slices/Main_HomePage_Slice/FlightPayments_SLICE';

export const store = configureStore({
  reducer: {
    HomePageMain: HomePage_SLICE,
    FlightPaymentsMain: FlightPayments_SLICE,
  }
});
