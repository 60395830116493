import React from 'react'
import "./Wallets.css"

function Wallets() {
  return (
    <div>
      
    </div>
  )
}

export default Wallets
