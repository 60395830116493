import React from 'react'
import "./EMI.css"

function EMI() {
  return (
    <div>
      
    </div>
  )
}

export default EMI
