import React from "react";
import "./fligthdetails.css";
import images from "../../../../../Utils/importImages";
import { formatTime, getTotalMinutes } from "../../../../../Utils/Date_Time_Calendar_Functions";


function TagsContainer({ data, index, single , Theme}) {
  // All Available Tags

  // "ChangeOfAirport"
  // "BagSame"
  // "TechnicalStopage"
  // "RecheckInbaggage"
  // "TerminalChange"
  // "TransitVisa"
  // "SelfTransferBaggage"
  // "ChangeOfPlanes"

  // console.log("sindei the tags container : " , {data, index, single , Theme});
  
  if (single == true) {
    if(data[index].ItenaryDetails.Tags.TechnicalStopage.Required == true){
      return (
        <div className="frame-2-5 frame-2-10">
          <div className="frame-238-2">

              <div className="frame-241-2">
                <div className="frame-207-1">
                  <div className="frame-173-2">
                    <div className="width-of-img">
                      <img
                        src={images[Theme == "proxima" ? "layover-blue-icon.svg" : "layover-gold-icon.svg"]}
                        // src={images["technical-stoppage-blue.svg"]}
                        alt="technical stoppage blue"
                      />
         
                    </div>
                    <div className="frame-242-9">
                      <div className={`self-transfer-baggage ${Theme}_semibold color_${Theme == "proxima" ? "endeavour" : "burning-gold"} font14`}>
                        Technical Stoppage
                      </div>
                      <div className={`you-must-self-transf ${Theme}_regular color_gravel font13`}>
                        {
                          data[index].ItenaryDetails.Tags.TechnicalStopage
                            .Message
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
         
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  return (
    <div className="frame-2-5 frame-2-10">
      <div className="frame-238-2">
        <div className="frame-238-3">
          <div className="frame-237-2">
            <div className="frame-237-2-2">
              <div className="width-of-img">
                <img
                  className="layover-img"
                  src={images[Theme == "proxima" ? "layover-blue-icon.svg" : "layover-gold-icon.svg"]}
                  alt="image"
                />
                
              </div>
              <div className={`x3hr-45m-3 ${Theme}_semibold color_${Theme == "proxima" ? "endeavour" : "burning-gold"} font14`}>
                {formatTime(
                  getTotalMinutes(
                    data[index[0]].Destination.ArrTime,
                    data[index[1]].Origin.DepTime
                  )
                )}
              </div>
            </div>
            <div className="frame-2-3 frame-2-10">
              <div className={`text-313 text ${Theme}_regular color_eerie-black font13`}>
                |
              </div>
            </div>
            <div className={`layover-in-tashkent-3 ${Theme}_regular color_eerie-black font14`}>
              Layover in {data[index[0]].Destination.Airport.CityName}
            </div>
          </div>
        </div>

        {data[index[0]].ItenaryDetails.Tags.TechnicalStopage.Required ==
          true && (
          <div className="frame-241-2">
            <div className="frame-207-1">
              <div className="frame-173-2">
                <div className="width-of-img">
                  <img
                    src={images[Theme == "proxima" ? "layover-blue-icon.svg" : "layover-gold-icon.svg"]}
                    // src={images["technical-stoppage-blue.svg"]}
                    alt="technical stoppage blue"
                  />
                </div>
                <div className="frame-242-9">
                  <div className={`self-transfer-baggage ${Theme}_semibold color_${Theme == "proxima" ? "endeavour" : "burning-gold"} font14`}>
                    Technical Stoppage
                  </div>
                  <div className={`you-must-self-transf ${Theme}_regular color_gravel font13`}>
                    {
                      data[index[0]].ItenaryDetails.Tags.TechnicalStopage
                        .Message
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {data[index[1]].ItenaryDetails.Tags.SelfTransferBaggage.Required ==
          true && (
          <div className="frame-241-2">
            <div className="frame-207-1">
              <div className="frame-173-2">
                <div className="width-of-img">
                  <img
                    src={images[Theme == "proxima" ? "self-transfter-blue.svg" : "self-transfer-gold.svg"]}
                    alt="Frame 242"
                  />
                </div>
                <div className="frame-242-9">
                  <div className={`self-transfer-baggage ${Theme}_semibold color_${Theme == "proxima" ? "endeavour" : "burning-gold"} font14`}>
                    Self Transfer Baggage
                  </div>
                  <div className={`you-must-self-transf ${Theme}_regular color_gravel font13`}>
                    {
                      data[index[0]].ItenaryDetails.Tags.SelfTransferBaggage
                        .Message
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {data[index[1]].ItenaryDetails.Tags.TransitVisa.Required == true && (
          <div className="frame-241-2">
            <div className="frame-207-1">
              <div className="frame-173-2">
                <div className="width-of-img">
                  <img src={images[Theme == "proxima" ? "transit-visa-blue.svg" : "transit-visa-gold.svg"]} alt="Frame 242" />
                </div>
                <div className="frame-242-9">
                  <div className={`self-transfer-baggage ${Theme}_semibold color_${Theme == "proxima" ? "endeavour" : "burning-gold"} font14`}>
                    Transit Visa
                  </div>
                  <div className={`you-must-self-transf ${Theme}_regular color_gravel font13`}>
                    {data[index[1]].ItenaryDetails.Tags.TransitVisa.Message}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {data[index[1]].ItenaryDetails.Tags.TerminalChange.Required == true && (
          <div className="frame-241-2">
            <div className="frame-207-1">
              <div className="frame-173-2">
                <div className="width-of-img">
                  <img
                    src={images[Theme == "proxima" ? "terminal-change-blue.svg" : "terminal-change-gold.svg"]}
                    alt="Frame 242"
                  />
                </div>
                <div className="frame-242-9">
                  <div className={`self-transfer-baggage ${Theme}_semibold color_${Theme == "proxima" ? "endeavour" : "burning-gold"} font14`}>
                    Terminal Change
                  </div>
                  <div className={`you-must-self-transf ${Theme}_regular color_gravel font13`}>
                    {data[index[1]].ItenaryDetails.Tags.TerminalChange.Message}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {data[index[1]].ItenaryDetails.Tags.RecheckInbaggage.Required ==
          true && (
          <div className="frame-241-2">
            <div className="frame-207-1">
              <div className="frame-173-2">
                <div className="width-of-img">
                  <img
                    src={images[Theme == "proxima" ? "re-checkin-bag-blue.svg" : "re-checkin-bag-gold.svg"]}
                    alt="Frame 242"
                  />
                </div>
                <div className="frame-242-9">
                  <div className={`self-transfer-baggage ${Theme}_semibold color_${Theme == "proxima" ? "endeavour" : "burning-gold"} font14`}>
                    ReCheckin bag
                  </div>
                  <div className={`you-must-self-transf ${Theme}_regular color_gravel font13`}>
                    {
                      data[index[1]].ItenaryDetails.Tags.RecheckInbaggage
                        .Message
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {data[index[1]].ItenaryDetails.Tags.BagSame.Required == true && (
          <div className="frame-241-2">
            <div className="frame-207-1">
              <div className="frame-173-2">
                <div className="width-of-img">
                  <img
                    src={images[Theme == "proxima" ? "re-checkin-bag-blue.svg" : "re-checkin-bag-gold.svg"]}
                    alt="Frame 242"
                  />
                </div>
                <div className="frame-242-9">
                  <div className={`self-transfer-baggage ${Theme}_semibold color_${Theme == "proxima" ? "endeavour" : "burning-gold"} font14`}>
                    Baggage Not Same
                  </div>
                  <div className={`you-must-self-transf ${Theme}_regular color_gravel font13`}>
                    {data[index[1]].ItenaryDetails.Tags.BagSame.Message}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {data[index[1]].ItenaryDetails.Tags.ChangeOfAirport.Required ==
          true && (
          <div className="frame-241-2">
            <div className="frame-207-1">
              <div className="frame-173-2">
                <div className="width-of-img">
                  <img
                    src={images[Theme == "proxima" ? "re-checkin-bag-blue.svg" : "re-checkin-bag-gold.svg"]}
                    alt="Frame 242"
                  />
                </div>
                <div className="frame-242-9">
                  <div className={`self-transfer-baggage ${Theme}_semibold color_${Theme == "proxima" ? "endeavour" : "burning-gold"} font14`}>
                    Change Of Airports
                  </div>
                  <div className={`you-must-self-transf ${Theme}_regular color_gravel font13`}>
                    {data[index[1]].ItenaryDetails.Tags.ChangeOfAirport.Message}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default TagsContainer;
