import React, { useState } from 'react';
import images from '../../../../../../Utils/importImages';
import './FlightDetailsIndicator.css';
import DataBar from './DataBar';

function FlightDetailsIndicator({ From, To, Stops, Time, Refundability, FFName, Theme, Type, isReturn, isTrip, TripName }) {

  return (
    <div className={`FD-Header ${Theme}`}>
      <div className="frame-24">
        <div className="frame-2-1">
          <div className={`x-flight-details ${Theme}_regular color_white font13`}>
            {(isReturn || isTrip) && TripName} FLIGHT DETAILS
          </div>
          <div className="frame-24-1 frame-24-8">
            <div className="frame-2-2">
              <div className="frame-202">
                <img src={images['white-clock.svg']} alt="Vector 50" />
                <div className={`x20h-40m ${Theme}_regular color_white font14`}>{Time}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="frame-268-1 frame-268-3">
        {Type === 1 && (
            <DataBar Theme={Theme} From={From} Stops={Stops} Refundability={Refundability} FFName={FFName} To={To} /> 
        )}
        {Type === 2 && (
            <>
            <DataBar Theme={Theme} From={From[0]} Stops={Stops[0]} Refundability={Refundability[0]} FFName={FFName[0]} To={To[0]} /> 
            <DataBar Theme={Theme} From={From[1]} Stops={Stops[1]} Refundability={Refundability[1]} FFName={FFName[1]} To={To[1]} /> 

          </>
        )}
      </div>
    </div>
  );
}

export default FlightDetailsIndicator;
