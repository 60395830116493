import React from "react";
import "./Timer.css";
import images from "../../../../Utils/importImages";

function Timer({ Theme }) {
  return (
    <div className="timer-bar">
      <div className="content">
        <div className="main-content">
          <div className={`${Theme}_semibold font20 color_deep-ocean`}>
            Payment
          </div>

          <div className="timer-wrapper">
            <img src={images["time-icon-payment-head.svg"]} alt="" />
            <div className={`${Theme}_semibold font18 color_deep-ocean`}>
              Time Left : 8m 30s
            </div>
          </div>
        </div>
        <div className="main-content-hidden">
         
    
        </div>
      </div>
    </div>
  );
}

export default Timer;
